import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Utility function to detect iOS devices
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// Apply iOS-specific styling
if (isIOS()) {
  document.documentElement.classList.add('ios');
}

// Set dynamic viewport height for better mobile experience
const setAppHeight = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
};

// Initialize viewport height
setAppHeight();

// Update viewport height on resize and orientation change
window.addEventListener('resize', setAppHeight);
window.addEventListener('orientationchange', setAppHeight);

// Remove any conflicting static header styles that might be present
document.addEventListener('DOMContentLoaded', () => {
  // Remove any static headers from the HTML
  const staticHeaders = document.querySelectorAll('.main-header:not([data-source="react-component"])');
  staticHeaders.forEach(header => {
    header?.parentNode?.removeChild(header);
  });

  // Remove any inline styles that might conflict with React header
  const criticalStyleTag = document.getElementById('critical-mobile-header-styles');
  if (criticalStyleTag?.parentNode) {
    criticalStyleTag.parentNode.removeChild(criticalStyleTag);
  }
});

// Handle tab selection for code examples
window.showTab = (tabId, event) => {
  document.querySelectorAll('.tab-content').forEach(tab => tab.classList.remove('active'));
  const selectedTab = document.getElementById(tabId);
  if (selectedTab) selectedTab.classList.add('active');

  document.querySelectorAll('.tab-button').forEach(button => button.classList.remove('active'));
  event?.target?.classList.add('active');
};

// Initialize React application after ensuring no conflicts
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
