import React, { useState, useEffect, useRef } from 'react';
import { FiHome, FiCode, FiHelpCircle, FiLogOut, FiSearch, FiGithub, FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import MobileHelp from './MobileHelp';
import './MobileMenu.css';
import { FaTimes } from 'react-icons/fa';

// Navigation items with icons and descriptions
const NAV_ITEMS = [
  { path: '/', label: 'Home', icon: <FiHome />, description: 'Return to homepage' },
  { path: '/api', label: 'API Services', icon: <FiCode />, description: 'Explore our API services' },
  { path: '/docs', label: 'Documentation', icon: <FiCode />, description: 'View detailed documentation' },
  { path: '/help', label: 'Help', icon: <FiHelpCircle />, description: 'Get help and support' },
  { path: '/login', label: 'Login', icon: <FiLogOut />, description: 'Sign in to your account' }
];

const MobileMenu = ({ isOpen, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filteredItems, setFilteredItems] = useState(NAV_ITEMS);
  const [showHelp, setShowHelp] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const scrollY = useRef(0);
  const searchInputRef = useRef(null);
  const menuRef = useRef(null);

  // Fuzzy search function with improved matching
  const fuzzySearch = (query, items) => {
    if (!query) return items;
    const searchTerm = query.toLowerCase();
    return items.filter(item => {
      const labelMatch = item.label.toLowerCase().includes(searchTerm);
      const descriptionMatch = item.description.toLowerCase().includes(searchTerm);
      const pathMatch = item.path.toLowerCase().includes(searchTerm);
      return labelMatch || descriptionMatch || pathMatch;
    });
  };

  // Handle search input changes with debounce
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSelectedIndex(-1);
    setFilteredItems(fuzzySearch(query, NAV_ITEMS));
  };

  // Enhanced keyboard navigation
  const handleKeyDown = (e) => {
    if (!filteredItems.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < filteredItems.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev > 0 ? prev - 1 : filteredItems.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < filteredItems.length) {
          const item = filteredItems[selectedIndex];
          handleItemClick(item);
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (searchQuery) {
          setSearchQuery('');
          setFilteredItems(NAV_ITEMS);
          setSelectedIndex(-1);
        } else {
          handleClose();
        }
        break;
      default:
        break;
    }
  };

  // Handle item click with animation
  const handleItemClick = (item) => {
    if (item.path === '/help') {
      setShowHelp(true);
    } else {
      handleClose();
      window.location.href = item.path;
    }
  };

  // Focus management
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Handle scroll locking with improved cleanup and smooth transitions
  useEffect(() => {
    if (isOpen) {
      scrollY.current = window.scrollY;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY.current}px`;
      document.body.style.width = '100%';
      document.body.style.height = '100%';
      document.body.style.transition = 'transform 0.3s ease-out';
    } else {
      document.body.style.transition = 'transform 0.3s ease-out';
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.height = '';
      window.scrollTo({
        top: scrollY.current,
        behavior: 'smooth'
      });
    }

    const timer = setTimeout(() => {
      setIsVisible(isOpen);
    }, 50);

    return () => {
      clearTimeout(timer);
      if (isOpen) {
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.body.style.height = '';
        document.body.style.transition = '';
        window.scrollTo({
          top: scrollY.current,
          behavior: 'smooth'
        });
      }
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    setSearchQuery('');
    setFilteredItems(NAV_ITEMS);
    setSelectedIndex(-1);
    setShowHelp(false);
    setIsSearchFocused(false);
    onClose();
  };

  const handleHelpClose = () => {
    setShowHelp(false);
  };

  // Handle overlay click and keyboard events
  const handleOverlayInteraction = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e.key === 'Enter' || e.key === ' '))) {
      e.preventDefault();
      handleClose();
    }
  };

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.button
            className="mobile-menu-overlay"
            onClick={handleOverlayInteraction}
            onKeyDown={handleOverlayInteraction}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            aria-label="Close menu overlay"
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isVisible && (
          <motion.nav
            ref={menuRef}
            className="mobile-menu"
            aria-label="Mobile navigation"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ 
              type: "spring",
              damping: 25,
              stiffness: 300,
              mass: 0.8
            }}
          >
            <div className="mobile-menu-header">
              <h2 className="mobile-menu-title">Menu</h2>
              <motion.button 
                className="mobile-menu-close"
                onClick={onClose}
                aria-label="Close menu"
                whileHover={{ rotate: 90 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaTimes />
              </motion.button>
            </div>

            <div className="mobile-menu-search">
              <motion.div 
                className={`search-container ${isSearchFocused ? 'focused' : ''}`}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
              >
                <FiSearch className="search-icon" />
                <input
                  ref={searchInputRef}
                  type="text"
                  placeholder="Search menu..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  aria-label="Search menu items"
                />
              </motion.div>
            </div>

            <div className="mobile-menu-content">
              <div className="mobile-nav-items">
                {filteredItems.map((item, index) => (
                  <motion.button
                    key={item.path}
                    type="button"
                    className={`mobile-nav-item ${index === selectedIndex ? 'selected' : ''}`}
                    onClick={() => handleItemClick(item)}
                    whileHover={{ x: 4, scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ 
                      delay: index * 0.05,
                      duration: 0.2,
                      ease: "easeOut"
                    }}
                  >
                    {item.icon}
                    <div className="mobile-nav-item-content">
                      <span className="mobile-nav-item-label">{item.label}</span>
                      <span className="mobile-nav-item-description">{item.description}</span>
                    </div>
                  </motion.button>
                ))}
              </div>
            </div>

            <div className="mobile-menu-footer">
              <motion.a 
                href="https://github.com/yourusername/bluejs" 
                target="_blank" 
                rel="noopener noreferrer"
                className="mobile-menu-github"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <FiGithub /> View on GitHub
              </motion.a>
              <motion.button 
                className="mobile-menu-get-started"
                onClick={() => {
                  handleClose();
                  window.location.href = '/api';
                }}
                whileHover={{ scale: 1.02, y: -2 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                Get Started <FiArrowRight />
              </motion.button>
            </div>
          </motion.nav>
        )}
      </AnimatePresence>

      <MobileHelp isOpen={showHelp} onClose={handleHelpClose} />
    </>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MobileMenu; 