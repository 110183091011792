import React, { Suspense, memo } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

// Lazy load components with prefetch capability and timeout
const lazyLoad = (importFunc) => {
  return React.lazy(() => 
    Promise.race([
      importFunc(),
      new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Loading timeout')), 10000)
      )
    ]).catch(error => {
      console.error('Error loading component:', error);
      return { default: () => <div>Error loading component. Please try again.</div> };
    })
  );
};

// Lazy load components
const HomePage = lazyLoad(() => import('./components/Homepage'));
const ApiServicePage = lazyLoad(() => import('./components/ApiServicePage'));
const DocumentationPage = lazyLoad(() => import('./components/DocumentationPage'));
const NotFoundPage = lazyLoad(() => import('./components/NotFoundPage'));
const Usage = lazyLoad(() => import('./components/Usage'));
const CodeExamples = lazyLoad(() => import('./components/CodeExamples'));

// Optimized loader component
const Loader = memo(() => (
  <div className="loader-container">
    <div className="loader-spinner">
      <div className="spinner-ring"></div>
      <output className="spinner-text">Loading Bleu.js</output>
    </div>
  </div>
));

Loader.displayName = 'Loader';

// Custom ErrorBoundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    } else {
      console.error("Component Error:", error);
      console.error("Error Info:", errorInfo);
    }
    
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  resetErrorBoundary() {
    if (this.props.onReset) {
      this.props.onReset();
    }
    this.setState({ hasError: false, error: null, errorInfo: null });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container" role="alert">
          <h2>Something went wrong</h2>
          <p>We're sorry, but there was an error loading this page.</p>
          {process.env.NODE_ENV === 'development' && (
            <div className="error-details">
              <pre>{this.state.error?.message}</pre>
              <pre>{this.state.error?.stack}</pre>
              <pre>{this.state.errorInfo?.componentStack}</pre>
            </div>
          )}
          <div className="error-actions">
            <button
              className="error-reload-btn"
              onClick={this.resetErrorBoundary}
            >
              Try Again
            </button>
            <button
              className="error-home-btn"
              onClick={() => window.location.href = '/'}
            >
              Go to Homepage
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func,
  onReset: PropTypes.func
};

// App component with performance optimizations
const App = () => {
  return (
    <Router>
      <div className="app">
        <Header />
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/api" element={<ApiServicePage />} />
              <Route path="/docs" element={<DocumentationPage />} />
              <Route path="/usage" element={<Usage />} />
              <Route path="/examples" element={<CodeExamples />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
};

export default App;