import React, { useState, useEffect } from 'react';
import { FiX, FiSearch, FiBook, FiCode, FiMessageSquare, FiMail, FiGithub, FiChevronRight, FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import './MobileHelp.css';

const HELP_SECTIONS = [
  {
    id: 'getting-started',
    title: 'Getting Started',
    icon: <FiBook />,
    items: [
      {
        title: 'Quick Start Guide',
        description: 'Learn how to integrate and use Bleu.js in your project',
        content: `# Quick Start Guide

## Installation
\`\`\`bash
npm install bleu.js
# or
yarn add bleu.js
\`\`\`

## Basic Usage
\`\`\`javascript
import { Bleu } from 'bleu.js';

const bleu = new Bleu({
  apiKey: process.env.REACT_APP_API_KEY || ''
});

// Start using Bleu.js
await bleu.initialize();
\`\`\`

## Next Steps
1. Configure your API settings
2. Set up your first model
3. Start making predictions`
      },
      {
        title: 'API Reference',
        description: 'Complete documentation of all available methods and options',
        content: `# API Reference

## Core Methods
- \`initialize()\`: Initialize the Bleu.js instance
- \`predict()\`: Make predictions using your model
- \`train()\`: Train your model with new data

## Configuration Options
- \`apiKey\`: Your Bleu.js API key
- \`model\`: The model to use for predictions
- \`options\`: Additional configuration options`
      }
    ]
  },
  {
    id: 'troubleshooting',
    title: 'Troubleshooting',
    icon: <FiCode />,
    items: [
      {
        title: 'Common Issues',
        description: 'Solutions to frequently encountered problems',
        content: `# Common Issues

## API Connection Errors
1. Check your internet connection
2. Verify your API key is correct
3. Ensure you're using the latest version

## Model Performance
1. Check your input data format
2. Verify model configuration
3. Monitor resource usage`
      },
      {
        title: 'Error Messages',
        description: 'Understanding and resolving error messages',
        content: `# Error Messages

## Common Error Codes
- \`E001\`: Invalid API key
- \`E002\`: Connection timeout
- \`E003\`: Invalid input data

## Resolution Steps
1. Check the error code
2. Review the error message
3. Follow the suggested resolution`
      }
    ]
  },
  {
    id: 'support',
    title: 'Support',
    icon: <FiMessageSquare />,
    items: [
      {
        title: 'Contact Support',
        description: 'Get help from our support team',
        content: `# Contact Support

## Support Channels
- Email: support@helloblue.ai
- GitHub Issues: [Report a bug](https://github.com/HelloblueAI/bleujs/issues)
- Documentation: [View docs](https://docs.bleu.js)

## Response Time
- Critical issues: < 2 hours
- General queries: < 24 hours`
      },
      {
        title: 'Community',
        description: 'Join our community for help and discussions',
        content: `# Community

## Resources
- Discord Server: [Join us](https://discord.gg/bleu)
- Stack Overflow: [bleu.js tag](https://stackoverflow.com/questions/tagged/bleu.js)
- GitHub Discussions: [View discussions](https://github.com/HelloblueAI/bleujs/discussions)`
      }
    ]
  }
];

const MobileHelp = ({ isOpen, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedSection, setExpandedSection] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [filteredSections, setFilteredSections] = useState(HELP_SECTIONS);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  // Handle search
  useEffect(() => {
    if (!searchQuery) {
      setFilteredSections(HELP_SECTIONS);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = HELP_SECTIONS.map(section => ({
      ...section,
      items: section.items.filter(item =>
        item.title.toLowerCase().includes(query) ||
        item.description.toLowerCase().includes(query) ||
        item.content.toLowerCase().includes(query)
      )
    })).filter(section => section.items.length > 0);

    setFilteredSections(filtered);
  }, [searchQuery]);

  const handleSectionToggle = (sectionId) => {
    setExpandedSection(expandedSection === sectionId ? null : sectionId);
    setExpandedItem(null);
  };

  const handleItemToggle = (sectionId, itemTitle) => {
    const itemKey = `${sectionId}-${itemTitle}`;
    setExpandedItem(expandedItem === itemKey ? null : itemKey);
  };

  const renderMarkdown = (content) => {
    // Simple markdown rendering for demonstration
    return content.split('\n').map((line, lineIndex) => {
      const key = `line-${lineIndex}`;
      if (line.startsWith('#')) {
        return <h2 key={key} className="help-content-heading">{line.slice(2)}</h2>;
      }
      if (line.startsWith('```')) {
        return <pre key={key} className="help-content-code">{line.slice(3)}</pre>;
      }
      return <p key={key} className="help-content-text">{line}</p>;
    });
  };

  return (
    <div className={`mobile-help ${isVisible ? 'active' : ''}`}>
      <div className="mobile-help-header">
        <button 
          className="mobile-help-close" 
          onClick={onClose}
          aria-label="Close help"
        >
          <FiX />
        </button>
        <h1 className="mobile-help-title">Help Center</h1>
      </div>

      <div className="mobile-help-search">
        <div className="search-container">
          <FiSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search help..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={searchQuery ? 'focused' : ''}
          />
        </div>
      </div>

      <div className="mobile-help-content">
        {filteredSections.map((section) => (
          <div key={`section-${section.id}`} className="help-section">
            <button
              className={`help-section-header ${expandedSection === section.id ? 'expanded' : ''}`}
              onClick={() => handleSectionToggle(section.id)}
            >
              <div className="help-section-title">
                {section.icon}
                <span>{section.title}</span>
              </div>
              {expandedSection === section.id ? <FiChevronDown /> : <FiChevronRight />}
            </button>

            {expandedSection === section.id && (
              <div className="help-section-items">
                {section.items.map((item) => (
                  <div key={`item-${section.id}-${item.title}`} className="help-item">
                    <button
                      className={`help-item-header ${expandedItem === `${section.id}-${item.title}` ? 'expanded' : ''}`}
                      onClick={() => handleItemToggle(section.id, item.title)}
                    >
                      <div className="help-item-title">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                      {expandedItem === `${section.id}-${item.title}` ? <FiChevronDown /> : <FiChevronRight />}
                    </button>

                    {expandedItem === `${section.id}-${item.title}` && (
                      <div className="help-item-content">
                        {renderMarkdown(item.content)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mobile-help-footer">
        <a href="https://github.com/HelloblueAI/bleujs" className="help-footer-link" target="_blank" rel="noopener noreferrer">
          <FiGithub /> GitHub
        </a>
        <a href="mailto:support@helloblue.ai" className="help-footer-link">
          <FiMail /> Contact Support
        </a>
      </div>
    </div>
  );
};

MobileHelp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MobileHelp; 