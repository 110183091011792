import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaGithub } from 'react-icons/fa';
import { FiHome, FiCode, FiBook, FiArrowRight, FiMenu, FiX } from 'react-icons/fi';
import BleuLogo from './BleuLogo';
import MobileMenu from './MobileMenu';
import './Header.css';

// Memoized throttle function with improved performance
const throttle = (() => {
  const throttled = {};
  return (key, func, limit) => {
    if (!throttled[key]) {
      throttled[key] = {
        lastRun: 0,
        timeout: null,
        pending: false
      };
    }
    const now = Date.now();
    if (now - throttled[key].lastRun >= limit) {
      func();
      throttled[key].lastRun = now;
    } else {
      throttled[key].pending = true;
      clearTimeout(throttled[key].timeout);
      throttled[key].timeout = setTimeout(() => {
        if (throttled[key].pending) {
          func();
          throttled[key].lastRun = Date.now();
          throttled[key].pending = false;
        }
      }, limit);
    }
  };
})();

// Navigation items with icons and descriptions
const NAV_ITEMS = [
  { path: '/', label: 'Home', icon: <FiHome />, description: 'Return to homepage' },
  { path: '/api', label: 'API Services', icon: <FiCode />, description: 'Explore our API services' },
  { path: '/docs', label: 'Documentation', icon: <FiBook />, description: 'View detailed documentation' },
  { path: '/usage', label: 'Usage', icon: <FiArrowRight />, description: 'Learn how to use Bleu.js' },
  { path: '/examples', label: 'Examples', icon: <FiBook />, description: 'See Bleu.js in action' },
  { path: '/blog', label: 'Blog', icon: <FiBook />, description: 'Read our latest blog posts' }
];

const Header = ({ onNavClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  const headerRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Enhanced mobile detection with improved breakpoint handling
  useEffect(() => {
    let resizeTimeout;
    const checkMobile = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        const isMobileView = window.innerWidth <= 768;
        setIsMobile(isMobileView);
        // Close menu when switching to desktop view
        if (!isMobileView && isMenuOpen) {
          setIsMenuOpen(false);
        }
      }, 100);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
      clearTimeout(resizeTimeout);
    };
  }, [isMenuOpen]);

  // Memoized handleGetStarted function with improved timing
  const handleGetStarted = useCallback(() => {
    if (location.pathname !== '/') {
      navigate('/');
      // Use requestAnimationFrame for smoother transition
      requestAnimationFrame(() => {
        const quickStartSection = document.querySelector('.quick-start-section');
        if (quickStartSection) {
          quickStartSection.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      });
    } else {
      const quickStartSection = document.querySelector('.quick-start-section');
      if (quickStartSection) {
        quickStartSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, [location.pathname, navigate]);

  // Enhanced scroll handling with improved performance
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && isMenuOpen) {
        handleMenuClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [isMenuOpen, handleMenuClose]);

  return (
    <>
      <header 
        ref={headerRef} 
        className={`header ${isScrolled ? 'scrolled' : ''} ${isMobile ? 'mobile' : 'desktop'}`}
        role="banner"
      >
        <div className="header-inner">
          <Link to="/" className="brand" aria-label="Bleu.js Home">
            <div className="brand-icon">
              <BleuLogo />
            </div>
            <div className="brand-text">
              <span className="brand-name">Bleu.js</span>
              <span className="brand-badge">AI Framework</span>
            </div>
          </Link>

          {/* Navigation */}
          <nav className="nav">
            <Link to="/" className="nav-link">Home</Link>
            <Link to="/api" className="nav-link">API Services</Link>
            <Link to="/docs" className="nav-link">Documentation</Link>
            <Link to="/usage" className="nav-link">Usage</Link>
            <Link to="/examples" className="nav-link">Examples</Link>
          </nav>

          {/* Actions */}
          <div className="header-actions">
            <a
              href="https://github.com/HelloblueAI/bleujs"
              target="_blank"
              rel="noopener noreferrer"
              className="github-button"
              aria-label="View Bleu.js on GitHub"
            >
              <FaGithub />
            </a>
            <button 
              onClick={handleGetStarted} 
              className="get-started-button"
              aria-label="Get Started with Bleu.js"
            >
              Get Started
            </button>
          </div>

          {/* Mobile Menu Toggle */}
          <button
            className={`mobile-menu-toggle ${isMobile ? 'visible' : ''}`}
            onClick={handleMenuToggle}
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMenuOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      <MobileMenu isOpen={isMenuOpen} onClose={handleMenuClose} />
    </>
  );
};

Header.propTypes = {
  onNavClick: PropTypes.func
};

export default Header;