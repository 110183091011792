import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaArrowUp, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  // Update year automatically
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  // Show/hide scroll to top button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="footer">
      <div className="footer-pattern"></div>

      <div className="footer-content container">
        <div className="footer-grid">
          {/* Branding Section */}
          <div className="footer-brand">
            <Link to="/" className="footer-logo">
              <svg width="50" height="55" viewBox="0 0 120 130" xmlns="http://www.w3.org/2000/svg" fill="none" className="footer-logo-svg">
                <path
                  d="M 63.16 81.57 L 41.07 81.57 L 41.07 55.53 L 107.64 55.53 L 107.64 121.98 L 81.57 121.98 L 81.57 99.89 L 59.51 121.98 L 0.56 121.98 L 0.56 15.2 L 26.63 15.2 L 26.63 96.03 L 48.75 96.03 Z"
                  fill="#00E0FF" fillOpacity="1" fillRule="nonzero"
                />
              </svg>
              <span className="footer-logo-text">Bleu.js</span>
            </Link>
            <p className="footer-tagline">The AI-Powered JavaScript Framework</p>
            <div className="footer-social">
              <a href="https://github.com/HelloblueAI/Bleu.js" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <FaGithub />
              </a>
            </div>
          </div>

          {/* Resources Section */}
          <div className="footer-nav">
            <h3>Resources</h3>
            <ul>
              <li><Link to="/docs">Documentation</Link></li>
              <li><Link to="/api">API Services</Link></li>
            </ul>
          </div>

          {/* Community Section */}
          <div className="footer-nav">
            <h3>Community</h3>
            <ul>
              <li><a href="https://github.com/HelloblueAI/Bleu.js" target="_blank" rel="noopener noreferrer">GitHub</a></li>
              <li><a href="https://stackoverflow.com/questions/tagged/bleujs" target="_blank" rel="noopener noreferrer">Stack Overflow</a></li>
            </ul>
          </div>

          {/* Support Contact Section */}
          <div className="footer-newsletter">
            <h3>Need Help?</h3>
            <p>Contact our support team for assistance.</p>
            <form className="newsletter-form" onSubmit={(e) => {
              e.preventDefault();
              const email = e.target.email.value;
              window.location.href = `mailto:support@helloblue.ai?subject=Support Request&body=Email: ${email}%0D%0A%0D%0A`;
            }}>
              <input
                type="email"
                name="email"
                placeholder="Your email"
                aria-label="Email for support contact"
                required
              />
              <button type="submit" aria-label="Contact Support">
                <FaEnvelope />
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Separate copyright bar that's always visible */}
      <div className="footer-copyright-bar">
        <div className="container">
          <p className="copyright-text">&copy; {year} Helloblue, Inc. All rights reserved.</p>
        </div>
      </div>

      {/* Scroll to Top Button */}
      <button
        className={`scroll-top-btn ${showScrollTop ? 'visible' : ''}`}
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <FaArrowUp />
      </button>
    </footer>
  );
};

export default Footer;
