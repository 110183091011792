import React from 'react';
import PropTypes from 'prop-types';

const BleuLogo = ({ className }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 120 130"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <defs>
      <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur stdDeviation="2" result="blur" />
        <feComposite in="SourceGraphic" in2="blur" operator="over" />
      </filter>
    </defs>
    <path
      d="M 63.16 81.57 L 41.07 81.57 L 41.07 55.53 L 107.64 55.53 L 107.64 121.98 L 81.57 121.98 L 81.57 99.89 L 59.51 121.98 L 0.56 121.98 L 0.56 15.2 L 26.63 15.2 L 26.63 96.03 L 48.75 96.03 Z"
      fill="#00E5FF"
      fillOpacity="1"
      fillRule="nonzero"
      filter="url(#glow)"
    />
  </svg>
);

BleuLogo.propTypes = {
  className: PropTypes.string
};

BleuLogo.defaultProps = {
  className: ''
};

export default BleuLogo; 